/* Here all text and images for pages can be changed */
class PortfolioProject {
    constructor(projectName,briefDescription,imgPath,imgPath2,intro,body1,body2,body3,final){
        this.projectName = projectName;
        this.briefDescription = briefDescription;
        this.imgPath = imgPath;
        this.imgPath2 = imgPath2;
        this.intro = intro;
        this.body1 = body1;
        this.body2 = body2;
        this.body3 = body3;
        this.final = final;
        this.uniqueId = projectName.replace(' ', '_');
    }
}
const PortfolioProjectArray = [
    {
        uniqueId: 'HiQ_Conference',
        projectName: 'HiQ Conference',
        stats: {
            type: ['Refurbishment'],
            date: `21st June 2024`,
            client: `HiQ`,
            industry: 'Automotive'
        },
        tileImgs: ['./imgs/hiq_conference/postcard4.jpg','./imgs/hiq_conference/blokes.jpg'],
        topBannerImgs: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/redditch_render.jpg','./../imgs/redditch/redditch_final2.jpg'],
        carousel: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg','./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg'],
        beforeAfter1: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg'],
        beforeAfter2: ['./../imgs/redditch/before2.jpg','./../imgs/redditch/after2.jpg'],
        beforeAfter3: ['./../imgs/redditch/before3.jpg','./../imgs/redditch/after3.jpg'],
        briefDescription: '',
        imgPath: './imgs/redditch/redditch_final2.jpg',
        imgPath2: './imgs/redditch/redditch_final3.jpg',
        intro: `The Redditch site has been transformed from what was once a tired looking garage into one of the brand's flagship centres.`
    },
    {
        uniqueId: 'Vulco_&_Premio_CI_Rollout',
        projectName: 'Vulco & Premio CI Rollout',
        stats: {
            type: ['Refurbishment'],
            date: `21st June 2024`,
            client: `HiQ`,
            industry: 'Automotive'
        },
        tileImgs: ['./imgs/vulco_premio_rollout/seating_area.jpg','./imgs/vulco_premio_rollout/darka_concrete.jpg'],
        topBannerImgs: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/redditch_render.jpg','./../imgs/redditch/redditch_final2.jpg'],
        carousel: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg','./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg'],
        beforeAfter1: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg'],
        beforeAfter2: ['./../imgs/redditch/before2.jpg','./../imgs/redditch/after2.jpg'],
        beforeAfter3: ['./../imgs/redditch/before3.jpg','./../imgs/redditch/after3.jpg'],
        briefDescription: '',
        imgPath: './imgs/redditch/redditch_final2.jpg',
        imgPath2: './imgs/redditch/redditch_final3.jpg',
        intro: `The Redditch site has been transformed from what was once a tired looking garage into one of the brand's flagship centres.`
    },

    {
        uniqueId: 'HiQ_Redditch',
        projectName: 'HiQ Redditch',
        stats: {
            type: ['Refurbishment'],
            date: `21st June 2024`,
            client: `HiQ`,
            industry: 'Automotive'
        },
        tileImgs: ['./imgs/redditch/final4.jpg','./imgs/redditch/redditch_final2.jpg'],
        topBannerImgs: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/redditch_render.jpg','./../imgs/redditch/redditch_final2.jpg'],
        carousel: [
            {
                id: 0,
                name: 'Reception Area Before',
                description: `Redditch's tired looking greeting space before renovations`,
                img: './../imgs/redditch/redditch_before.jpg'
            },
            {
                id: 1,
                name: 'Reception Area After',
                description: 'The new and improved waiting area for customers',
                img: './../imgs/redditch/after.jpg'
            },
            {
                id: 2,
                name: 'Desk Area',
                description: 'A refreshed and tidy work space for staff ',
                img: './../imgs/redditch/after2.jpg'
            }

            // {   
            //     id: 3,
            //     name: 'Staff Area',
            //     description: 'Lighter and cleaner this space is now more accomodating for staff members',
            //     img: './../imgs/redditch/after3.jpg'
            // },
            // {   
            //     id: 4,
            //     name: 'Render',
            //     description: 'The final render produced before construction began',
            //     img: './../imgs/redditch/redditch_render.jpg'
            // }
        ],
        beforeAfter1: ['./../imgs/redditch/redditch_render.jpg','./../imgs/redditch/redditch_final2.jpg'],
        beforeAfter2: ['./../imgs/redditch/before2.jpg','./../imgs/redditch/after2.jpg'],
        beforeAfter3: ['./../imgs/redditch/before3.jpg','./../imgs/redditch/after3.jpg'],
        briefDescription: '',
        imgPath: './imgs/redditch/redditch_final2.jpg',
        imgPath2: './imgs/redditch/redditch_final3.jpg',
        intro: `The Redditch site has been transformed from what was once a tired looking garage into one of the brand's flagship centres.`
    },
    {
        uniqueId: 'HiQ_Bicester',
        projectName: 'HiQ Bicester',
        stats: {
            type: ['Refurbishment'],
            date: `21st June 2024`,
            client: `HiQ`,
            industry: 'Automotive'
        },
        tileImgs: ['./imgs/hiq_bicester/door_open.jpg','./imgs/hiq_bicester/with_car.jpg'],
        topBannerImgs: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/redditch_render.jpg','./../imgs/redditch/redditch_final2.jpg'],
        carousel: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg','./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg'],
        beforeAfter1: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg'],
        beforeAfter2: ['./../imgs/redditch/before2.jpg','./../imgs/redditch/after2.jpg'],
        beforeAfter3: ['./../imgs/redditch/before3.jpg','./../imgs/redditch/after3.jpg'],
        briefDescription: '',
        imgPath: './imgs/redditch/redditch_final2.jpg',
        imgPath2: './imgs/redditch/redditch_final3.jpg',
        intro: `The Redditch site has been transformed from what was once a tired looking garage into one of the brand's flagship centres.`
    },
    {
        uniqueId: 'Vulco_&_Premio_Guidelines',
        projectName: 'Vulco & Premio Guidelines',
        stats: {
            type: ['Refurbishment'],
            date: `21st June 2024`,
            client: `HiQ`,
            industry: 'Automotive'
        },
        tileImgs: ['./imgs/vulco_premio_guideline/premio1.jpg','./imgs/redditch/redditch_final3.jpg'],
        topBannerImgs: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/redditch_render.jpg','./../imgs/redditch/redditch_final2.jpg'],
        carousel: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg','./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg'],
        beforeAfter1: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg'],
        beforeAfter2: ['./../imgs/redditch/before2.jpg','./../imgs/redditch/after2.jpg'],
        beforeAfter3: ['./../imgs/redditch/before3.jpg','./../imgs/redditch/after3.jpg'],
        briefDescription: '',
        imgPath: './imgs/redditch/redditch_final2.jpg',
        imgPath2: './imgs/redditch/redditch_final3.jpg',
        intro: `The Redditch site has been transformed from what was once a tired looking garage into one of the brand's flagship centres.`
    },
    {
        uniqueId: 'Logo_Development',
        projectName: 'Logo Development',
        stats: {
            type: ['Refurbishment'],
            date: `21st June 2024`,
            client: `HiQ`,
            industry: 'Automotive'
        },
        tileImgs: ['./imgs/logo_develop/Logos.jpg','./imgs/logo_develop/Company_Logo_Light.jpg'],
        topBannerImgs: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/redditch_render.jpg','./../imgs/redditch/redditch_final2.jpg'],
        carousel: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg','./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg','./../imgs/redditch/after2.jpg','./../imgs/redditch/after3.jpg'],
        beforeAfter1: ['./../imgs/redditch/redditch_before.jpg','./../imgs/redditch/after.jpg'],
        beforeAfter2: ['./../imgs/redditch/before2.jpg','./../imgs/redditch/after2.jpg'],
        beforeAfter3: ['./../imgs/redditch/before3.jpg','./../imgs/redditch/after3.jpg'],
        briefDescription: '',
        imgPath: './imgs/redditch/redditch_final2.jpg',
        imgPath2: './imgs/redditch/redditch_final3.jpg',
        intro: `The Redditch site has been transformed from what was once a tired looking garage into one of the brand's flagship centres.`
    }
];

export { PortfolioProjectArray };