import React, { useEffect, useState } from "react";
import styles from './Portfolio.module.css';
import { PortfolioProjectArray } from "../../PageData";
import { useParams } from "react-router-dom";
import BannerSwitcher from "./BannerSwitcher";
import BannerReveal from "./BannerReveal";
import Carousel from "./Carousel";

export default function PortfolioPage(){

    const { id } = useParams();

    const [projectData,setProjectData] = useState({
        uniqueId: '',
        projectName: '',
        stats: {
            type: [],
            date: '',
            client: '',
            industry: ''
        },
        tileImgs: [],
        topBannerImgs: [],
        carousel: [{id: 0, name: '', description: '', img: ''}],
        beforeAfter1: [],
        beforeAfter2: [],
        beforeAfter3: [],
        briefDescription: '',
        imgPath: '',
        imgPath2: '',
        intro: ''
    });

    useEffect(() => {
        window.scrollTo(0,0);
        setProjectData(PortfolioProjectArray.filter((project) => project.uniqueId === id.replace('mobile',''))[0]);
    },[]);



    const typeFunction = (array) => {
        const arrayLength = array.length;
        if(arrayLength > 1){
            let typeString = '';
            for(let i = 0; i < arrayLength; i++){
                if(i !== arrayLength - 1){
                    typeString += array[i]+' & ';
                } else {
                    typeString += array[i];
                }
            }
            return typeString;
        } else {
            return array[0];
        }
    }
    if(projectData){
        return(
            <>
            <BannerSwitcher imgPathArray={projectData.topBannerImgs}/>
            <main>
                <div className={styles.maskContainer}></div>
                <div className={styles.titleContainer}>
                    <h1>{projectData.projectName}</h1>
                </div>
                <div className={styles.bodySection}>
                    <div className={styles.introSection}>
                        <h6 className={styles.intro}>{projectData.intro}</h6>
                        <div className={styles.stats}>
                            <div className={styles.statContainer}>
                                <p className={styles.type}>Type</p>
                                <p className={styles.stat}>{typeFunction(projectData.stats.type)}</p>
                            </div>
                            <div className={styles.statContainer}>
                                <p className={styles.type}>Date</p>
                                <p className={styles.stat}>{projectData.stats.date}</p>
                            </div>
                            <div className={styles.statContainer}>
                                <p className={styles.type}>Client</p>
                                <p className={styles.stat}>{projectData.stats.client}</p>
                            </div>
                            <div className={styles.statContainer}>
                                <p className={styles.type}>Industry</p>
                                <p className={styles.stat}>{projectData.stats.industry}</p>
                            </div>
                        </div>
                    </div>
                    <Carousel id="carousel1" imgArray={projectData.carousel} />
                    {/* <p className={styles.body1}>{projectData.body1}</p> */}
                    <BannerReveal id="bannerReveal1" img1={projectData.beforeAfter1[0]} img2={projectData.beforeAfter1[1]} />
                    <p className={styles.body1}>{projectData.body1}</p>
                </div>
            </main>
            </>
        )
    }
}