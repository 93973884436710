import React , { useEffect, useState } from "react";
import styles from './Carousel.module.css';

export default function Carousel({id,imgArray}){
    const viewportWidth = document.body.clientWidth;
    const viewportHeight = document.body.clientHeight;
    console.log(viewportWidth);
    console.log(viewportHeight);
    let imgWidth;
    let marginWidth;

    if(viewportWidth <= 900 && viewportHeight > viewportWidth){
        imgWidth = 0.8*viewportWidth;
        marginWidth = 0.1*viewportWidth;
    } else {
        imgWidth = 0.4*viewportWidth;
        marginWidth = 0.3*viewportWidth;
    }

    const [selected,setSelected] = useState(0);
    const [stateImgArray,setStateImgArray] = useState(imgArray);
    const [carouselPos,setCarouselPos] = useState((marginWidth)-(imgWidth));
    const [orderedArray,setOrderedArray] = useState([]);
    const [animationActive,setAnimationActive] = useState(false);
    const [animationInProcess,setAnimationInProcess] = useState(false);

    useEffect(() => {
        setStateImgArray(imgArray);
        if(imgArray.length > 1){
            setOrderedArray([imgArray[imgArray.length - 1],...imgArray.slice(0,imgArray.length - 1)]);
        } else {
            setOrderedArray(imgArray);
        }
    },[imgArray]);

    const changeSelected = (newVal) => {
        if(!animationInProcess){
            setSelected((prev) => {
                if(prev === 0 && newVal === imgArray.length - 1){
                    downOne(1);
                } else if (prev === imgArray.length - 1 && newVal === 0){
                    upOne(1);
                } else if(prev < newVal){
                    const iterations = newVal - prev;
                    upOne(iterations);
                } else if(prev > newVal){
                    const iterations = prev - newVal;
                    downOne(iterations);
                }
                return newVal;
            });
        }
        
    }
    
    // const upOne = (iterations) => {
    //     setOrderedArray((prevArray) => {
    //         if(prevArray){
    //             if(prevArray.length > 1){
    //                 let array = [...orderedArray];
    //                 for(let i = 0; i < iterations; i++){
    //                     const slice = array.shift();
    //                     array.push(slice);
    //                 }
    //                 return array;
    //             }
    //         }
    //     });
    // }

        // const downOne = (iterations) => {
    //     setOrderedArray((prevArray) => {
    //         if(prevArray){
    //             if(prevArray.length > 1){
    //                 let array = [...orderedArray];
    //                 for(let i = 0; i < iterations; i++){
    //                     const slice = array.pop();
    //                     array = [slice,...array];
    //                 }
    //                 return array;
    //             }
    //         }
    //     });
    // }

    const upOne = (iterations) => {
        setAnimationInProcess(true);
        let array = [...orderedArray];
        setOrderedArray((prevArray) => {
            if(prevArray){
                if(prevArray.length > 1){
                    const spliced = [...array].splice(0,iterations);
                    array = [...array,...spliced];
                    console.log(array);
                    return checkArrayUp(array,imgArray);
                }
            }
        });
        setAnimationActive(true);
        setCarouselPos((marginWidth)-((iterations+1)*imgWidth));
        setTimeout(() => {
            setAnimationActive(false);
            console.log(array);
            setOrderedArray((prevArray) => {
                if(prevArray){
                    if(prevArray.length > 1){
                        const spliced = [...array].splice(iterations,array.length);
                        console.log(spliced);
                        return [...spliced];
                    }
                }
            });
            setCarouselPos((marginWidth)-((1)*imgWidth));
            setTimeout(() => {
                setAnimationInProcess(false);
            },50);
        },300);
    }

    const downOne = (iterations) => {
        setAnimationInProcess(true);
        let array = [...orderedArray];
        setCarouselPos((marginWidth)-((1+iterations)*imgWidth));
        setOrderedArray((prevArray) => {
            if(prevArray){
                if(prevArray.length > 1){
                    const spliced = [...array].splice(array.length - iterations,iterations);
                    console.log(spliced);
                    array = [...spliced,...array];
                    console.log(array);
                    return checkArrayDown(array,imgArray);
                }
            }
        });
        setTimeout(() => {
            setAnimationActive(true);
            setCarouselPos((marginWidth)-((1)*imgWidth));           
            setOrderedArray((prevArray) => {
                if(prevArray){
                    if(prevArray.length > 1){
                        const spliced = [...array].splice(0,array.length-iterations);
                        console.log(spliced);
                        return [...spliced];
                    }
                }
            });
            setTimeout(() => {
                setAnimationActive(false);
                setTimeout(() => {
                    setAnimationInProcess(false);
                },50);
            },300);
        },1);
    }

    const checkArrayUp = (array,original) => {
        if (array.length - original.length > 1){
            array.pop();
        }
        return array;
    }

    const checkArrayDown = (array,original) => {
        if (array.length - original.length > 1){
            array.shift();
        }
        return array;
    }

    let i = 0;
    let j = 0;

    return(
        <div className={styles.largeContainer}>
            <div id={id} className={styles.container}>
                <div id={`${id}_inner`} className={`${styles.containerInner} ${animationActive? styles.containerAnimation : ''}`} style={{left: `${carouselPos}px`}}>
                    {orderedArray.map((img) => {
                        let k = i;
                        i++;
                        return (
                            <div onClick={() => changeSelected(img.id)} className={`${styles.imageContainer} ${selected === img.id? styles.selected : ''}`}>
                                <div className={styles.images} style={{backgroundImage: `url(${img.img})`}}></div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={styles.imgDescription}>
                <h6><strong>{imgArray[selected].name}</strong></h6>
                <p>{imgArray[selected].description}</p>
            </div>
            <div className={styles.circleSelectorContainer}>
                {imgArray.map((img) => {
                        let k = j;
                        j++;
                        return (
                            <div className={`${styles.circleSelector} ${selected === img.id? styles.selected : ''}`} onClick={() => changeSelected(img.id)}>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}